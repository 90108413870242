@use "mixins" as *
.nav
  height: 100%
  display: inline-flex
  margin: 0 auto
  min-width: 510px
  a
    min-width: 58px
    font-size: 12px
    color: rgb(var(--base_textColor))
    padding: 0 12px 1px
    @include flex
    justify-content: center
    position: relative
    white-space: nowrap
    text-transform: capitalize

    &:not(:first-of-type)
      margin-left: 24px

    &:hover
      color: rgb(var(--thirdaryColor))

    .bonusesIcon
      margin-left: 10px

  @media only screen and (max-width: 1360px)
    a:not(:first-of-type)
      margin-left: 14px
